import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { parseChallenges } from './parseChallenges';

export default function getClaims(
    headers?: HeadersWithoutIterator,
    wwwAuthenticateHeader?: string
): string | undefined {
    let authenticateHeader;
    if (wwwAuthenticateHeader) {
        authenticateHeader = wwwAuthenticateHeader;
    } else if (headers) {
        authenticateHeader = headers.get('WWW-Authenticate');
    }

    const claimsChallenge = authenticateHeader
        ? parseChallenges(authenticateHeader).claims
        : undefined;

    if (claimsChallenge) {
        try {
            return atob(claimsChallenge);
        } catch {
            // noop
        }
    }

    return undefined;
}
