import { AccountSourceType } from 'owa-account-source-list-types';
import { AUTHORITY_COMMON, AUTHORITY_CONSUMERS, AUTHORITY_ORGANIZATIONS } from './constants';
import { findMetatag } from 'owa-metatags';
import { isUrlPresent } from 'owa-config';

export function getAuthority(typeHint: AccountSourceType): string {
    if (typeHint === AccountSourceType.Office365) {
        const authorityUrl = getAuthorityUrlFromMetaTag();
        return authorityUrl ? `${authorityUrl}organizations/` : AUTHORITY_ORGANIZATIONS;
    } else if (typeHint === AccountSourceType.OutlookDotCom) {
        return AUTHORITY_CONSUMERS;
    }

    return AUTHORITY_COMMON;
}

export function getCommonAuthorityUrlFromMetaTag(): string {
    const authorityUrl = getAuthorityUrlFromMetaTag();
    return authorityUrl ? `${authorityUrl}common/` : AUTHORITY_COMMON;
}

export function getAuthorityUrlFromMetaTag(): string | undefined {
    // This meta tag is named 'aadAuthorityUrl' but it doesn't actually point to the /organizations endpoint
    const authorityUrl = (findMetatag('aadAuthorityUrl') || '').toLowerCase();
    return isUrlPresent(authorityUrl) ? authorityUrl : undefined;
}
